declare const admin_url: string

if (document.querySelector('#contestForm')) {

const competitionFormEl = document.querySelector('#contestForm')
const formButtonEl = competitionFormEl.querySelector('[type="submit"]')
const inputCheck = document.querySelectorAll('.js-check-input')
const formSuccessEl = document.querySelector('.form-success')

const addParticipant = (e) => {
    e.preventDefault()
    $(formButtonEl).addClass('opacity-50 pointer-events-none')

    const request = new XMLHttpRequest()
    const formData = new FormData(competitionFormEl)
    formData.append('action', 'apply_to_competition')
    console.log(formData)

    request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status == 200) {
            console.log(request.responseText)
            formSuccessEl.classList.remove('hidden')
            $(competitionFormEl).slideUp()
        } else {
			$(formButtonEl).removeClass('opacity-50 pointer-events-none')
		}
    }

    request.open('POST', admin_url, true)
    request.send(formData)
}


const checkInputs = (e) => {
    let countInputs = 0;
	let countInvalid = 0;
    Array.prototype.forEach.call(inputCheck, (input) => {
		console.log(input, input.type, input.checked)
        if (
			((input.type !== 'checkbox') && input.value != 0 && input.value.length > 0)
			|| (input.type === 'checkbox' && input.checked)
		) {
            countInputs++;
            input.classList.contains('is-invalid') ? input.classList.remove('is-invalid') : false;
        } else {
            input.classList.add('is-invalid');
			countInvalid++;
        }
    });

	if (countInvalid > 0) {
		$(competitionFormEl).addClass('needs-validation');
	} else {
		$(competitionFormEl).removeClass('needs-validation');
		addParticipant(e);
	}
}

formButtonEl.addEventListener('click', (e) => {
    e.preventDefault();
    checkInputs(e);
    // addParticipant(e);
});
};
