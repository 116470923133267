(function(window, undefined) {
    'use strict';

    const shareUrl = window.location.href;

    const initShare = (els: NodeListOf<HTMLAnchorElement>) => {
        els.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
    
                const network = el.getAttribute('data-platform');
                const url = el.getAttribute('data-url') || shareUrl;
                const title = el.getAttribute('data-title') || document.title;
                const description = el.getAttribute('data-description') || '';
    
                switch (network) {
                    case 'facebook':
                        openShareDialog(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
                        break;
                    case 'twitter-x':
                        openShareDialog(`https://twitter.com/intent/tweet?text=${title}&url=${url}`)
                        break;
                    case 'linkedin':
                        openShareDialog(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`)
                        break;
                    case 'whatsapp':
                        openShareDialog(`https://api.whatsapp.com/send?text=${title}%20${url}`)
                        break;
                    case 'pinterest':
                        openShareDialog(`https://pinterest.com/pin/create/button/?url=${url}&description=${title}`)
                        break;
                    case 'email':
                        openShareDialog(`mailto:?subject=${title}&body=${url}`)
                        break;
                    default:
                        break;
                }
            });
        });   
    }

    const openShareDialog = (url: string) => {
        const width = 626;
        const height = 436;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        window.open(url, 'share-dialog', `menubar=no,toolbar=no,status=no,width=${width},height=${height},top=${top},left=${left}`);
    }

    document.addEventListener('DOMContentLoaded', () => {
        const shareEls = document.querySelectorAll<HTMLAnchorElement>('.sharer-link');

        initShare(shareEls);
    })
})(window)

