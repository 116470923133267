if (document.querySelector('#contestStoreForm')) {

const competitionForm = document.querySelector('#contestStoreForm');
const formButton = document.querySelector('.skicka-c-form');
const inputCheck = document.querySelectorAll('.js-check-input');
const checkBox = document.querySelector('.js-checkbox-store');
const successMessage = document.querySelector('.thank-you-title');

const addStoreParticipant = (e) => {
    e.preventDefault();
    $(formButton).addClass("btn--loading");

    const request = new XMLHttpRequest();
    const formData = new FormData(competitionForm);
    formData.append('c-butik', $('#c-butik option:selected').val());
    formData.append('action', 'apply_to_store_competition');
    console.log(formData);

    request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status == 200) {
            console.log(request.responseText);
            successMessage.classList.add('sent');
            $(competitionForm).slideUp();
        }
    }

    request.open('POST', ajaxUrl, true);
    request.send(formData);
}


const checkInputs = (e) => {
    let countInputs = 0;
    Array.prototype.forEach.call(inputCheck, (input) => {
        if (input.value != 0 && input.value.length > 0) {
            countInputs++;
            input.classList.contains('required') ? input.classList.remove('required') : false;
            if (countInputs === 4) {
                if (document.querySelector('.js-checkbox-store:checked').length === checkBox.length) {
                    addStoreParticipant(e);
                }
            }
        } else {
            input.classList.add('required');
        }
    });
}

formButton.addEventListener('click', (e) => {
    e.preventDefault();
    checkInputs(e);
});
};

