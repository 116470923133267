$(function() {
	const CLASS_IS_SCROLLED = 'is-scrolled';

	const body = $('body');

	const debounce = (func: Function, delay: number) => {
		let timeoutId: ReturnType<typeof setTimeout>;
		return (...args: any[]) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				func.apply(null, args);
			}, delay);
		};
	};

	const handleScroll = () => {
		if ($(window).scrollTop() > 140) {
			body.addClass(CLASS_IS_SCROLLED);
		} else {
			body.removeClass(CLASS_IS_SCROLLED);
		}
	};

	$(window).on('scroll', debounce(handleScroll, 100));

	$('[data-target="#siteHeaderNav"]').on('click', function() {
		$(this).closest('.site-header').toggleClass('is-active');
		$(this).attr('aria-expanded', (i, attr) => {
			return attr === 'true' ? 'false' : 'true'
		})
	})
});