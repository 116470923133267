import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const productSlider = () => {
	const productSliderEl:HTMLElement|null = document.querySelector('.image-slider')

	if (!productSliderEl) return

	const productSlider = new Swiper(productSliderEl, {
		modules: [Navigation, Pagination],
		slidesPerView: 1,
		spaceBetween: 20,
		loop: true,
		pagination: {
			el: '.swiper-pagination'
		},
		navigation: {
			nextEl: '.swiper-button-next',
    		prevEl: '.swiper-button-prev'
		}
	})
}

export default productSlider