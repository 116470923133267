import {
    docCookies,
    maxAgeToGMT,
  } from './cookieHelper';

  const cookieName = 'approvedCookiePolicy';

  const cookieBarWrapper = document.getElementById('cookiebar');

  const cookieBarInner = document.getElementById('cookiebar-inner');

  class CookiePolicyBar {
    constructor() {
      this.approveButton = null;
      this.createApproveButton();
      this.approveButton.addEventListener('click', () => this.closeCookieBar(), false);
    }

    createApproveButton() {
      this.approveButton = document.createElement('button');
      this.approveButton.classList.add('cookie-bar__button');
      this.approveButton.innerHTML = 'OK';
      cookieBarInner.appendChild(this.approveButton);
    }
    showCookieBar() {
      cookieBarWrapper.classList.remove('cookie-bar--hidden');

    };
    closeCookieBar() {
      cookieBarWrapper.classList.add('cookie-bar--hidden');
      docCookies.setItem(cookieName, 1, maxAgeToGMT(31536e3));
      setTimeout(() => {
        cookieBarWrapper.remove();
      }, 1000);
    }

    // removeCookieBar() {
    //   const cookieCheck = document.getElementById.getElementById('cookiebar');

    //   if (cookieCheck == false || typeof cookieCheck === 'undefined') {
    //     return false;
    //   }

    //   cookieCheck.classList.add('cookie-bar--hidden');

    // }
  }

  const cookieNotificationBar = () => {
    const cookieBar = new CookiePolicyBar();

    if (!docCookies.hasItem(cookieName)) {
      cookieBar.showCookieBar();
    }
    document.getElementById('cookie-button').addEventListener('click', () => cookieBar.closeCookieBar() ,false);

  };

  export default cookieNotificationBar;