// import '../scss/style.scss'
import '../css/app.css'

import './jquery'
import './siteHeader'

import StoreLocator from './storeLocator'
import './competitionForm'
import './competitionStoreForm'
import './sharer'
import ProductSlider from './productSlider'
// import './competition-form'

import cookieNotificationBar from './cookieNotificationBar'

cookieNotificationBar()

if (
	document.body.classList.contains('post-type-archive-stores')
	|| document.body.classList.contains('single-stores')
) {
	StoreLocator()
}

if (
	document.body.classList.contains('single-products')
) {
	ProductSlider()
}